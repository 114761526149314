import { combineReducers } from "redux";
import app from "../app/reducer";
import grids from "../grids/reducer";
import title from "../title/reducer";
import { RootState } from "./state";

export const rootReducer = combineReducers<RootState>({
  app,
  title,
  grids
});
