import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Store } from "redux";
import { Config } from "../config";
import { ApiTokens } from "./ApiTokens";
import { isAxiosError } from "./isAxiosError";
import { refresh } from "./refresh";
import { loadTokens } from "./tokens";

let api: AxiosInstance | undefined;

function isSecondChanceCall(config: AxiosRequestConfig): boolean {
  return Boolean((config as any).__secondChance);
}

function markAsSecondChanceCall(config: AxiosRequestConfig): AxiosRequestConfig {
  const cfg = config as any;
  cfg.__secondChance = true;
  return cfg;
}

export function initializeApi(store: Store, apiConfig: Config) {
  api = axios.create({
    headers: {
      Pragma: "no-cache"
    },
    baseURL: apiConfig.apiServerUri
  });

  api.interceptors.request.use(async config => {
    const { accessToken } = await loadTokens();
    if (accessToken != null) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  api.interceptors.response.use(
    response => response,
    async error => {
      if (!isAxiosError(error)) {
        // window.console.log("[AxiosInterceptor] received non-axios error", error);
        throw error;
      }

      const { config, response } = error;
      if (response == null) {
        // window.console.log("[AxiosInterceptor] network error - cannot handle");
        throw error;
      }

      if (response.status !== 401) {
        // window.console.log(`[AxiosInterceptor] status code ${response.status}`);
        throw error;
      }

      if (isSecondChanceCall(error.config)) {
        // window.console.log("[AxiosInterceptor] refreshed call failed", error);
        throw error;
      }

      let newTokens: ApiTokens;
      try {
        newTokens = await refresh(store, apiConfig);
      } catch (e) {
        // window.console.log("[AxiosInterceptor] refresh call failed", e);
        throw error;
      }
      markAsSecondChanceCall(config);
      config.headers.Authorization = `Bearer ${newTokens.accessToken}`;
      return await api!.request(config);
    }
  );
}

export function getApi() {
  if (api == null) {
    throw new Error("Api not initialized. Call initalizeApi.");
  }
  return api;
}
