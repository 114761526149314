import { Reducer } from "redux";
import { Mutable } from "shared/types";
import { getType } from "typesafe-actions";
import { gridActions, GridsAction } from "./actions";
import { GridConfig, GridsState, initialGridsState } from "./state";

function hasChanged(key: keyof GridConfig, payload: Partial<GridConfig>, config: GridConfig) {
  return payload[key] !== undefined && payload[key] !== config[key];
}

function equals<T>(key: keyof T, t1: T, t2: T) {
  return t1[key] === t2[key];
}

function shallowEquals<T extends object>(t1: T, t2: T) {
  for (const key in t1) {
    if (t1.hasOwnProperty(key) && !equals(key, t1, t2)) {
      return false;
    }
  }
  return true;
}

const reducer: Reducer<GridsState, GridsAction> = (state = initialGridsState, action) => {
  switch (action.type) {
    case getType(gridActions.createConfig): {
      const { key, config } = action.payload;
      return {
        ...state,
        configs: { ...state.configs, [key]: config }
      };
    }
    case getType(gridActions.updateConfig): {
      const { key, config } = action.payload;
      const current = state.configs[key];
      if (current == null) {
        window.console.warn(`Trying to update non-existent grid ${key}`);
        return state;
      }

      const next: Mutable<GridConfig> = {
        ...current,
        ...config
      };

      if (hasChanged("pageSize", config, current) || hasChanged("search", config, current)) {
        next.page = 1;
      }

      if (shallowEquals(next, current)) {
        return state;
      }

      return {
        ...state,
        configs: {
          ...state.configs,
          [key]: next
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
