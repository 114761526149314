import { ApolloError } from "apollo-client";

export class ValidationError extends Error {
  constructor(message: string | undefined, public readonly data: { [key: string]: string }) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

interface ValidationErrors {
  [key: string]: string
}
const emptyValidationErrors: ValidationErrors = {};


export function extractValidationErrors(e: Error, prefix?:string): ValidationErrors | undefined {
  if (!(e instanceof ApolloError)) {
    return undefined;
  }

  const validationErrors = e.graphQLErrors.filter(err => err.message === "validation" || err.extensions?.data?.code === "VALIDATION");
  if (validationErrors.length <= 0) {
    return undefined;
  }

  return validationErrors
    .flatMap(c => Object.entries<string[]>(c.extensions?.data?.errors))
    .reduce((previous, [key, value]): ValidationErrors => {
      const transformedKey = prefix == null ? key : key.substr(`${prefix}.`.length);
      return {
        ...previous,
        [transformedKey]: [previous[transformedKey] ?? '', value.join(" ")].filter(c => c !== '').join(" ")
      };
    }, emptyValidationErrors);
}

export function buildValidationErrorFromApolloError(e: Error): ValidationError | undefined {
  const err = extractValidationErrors(e);
  if (err != null) {
    return new ValidationError("Validierung fehlgeschlagen", err);
  }
  return undefined;
}

export function tryBuildValidationErrorFromApolloError(e: Error): Error {
  return buildValidationErrorFromApolloError(e) || e;
}

export function rethrowValidation(e: Error) {
  var err = buildValidationErrorFromApolloError(e);
  if (err != null) {
    throw err;
  }
  throw e;
}
