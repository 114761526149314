import { QueryResult } from "@apollo/react-common";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { OperationVariables } from "apollo-client";
import { DocumentNode } from "apollo-link";
import { useEffect, useMemo, useState } from "react";

export function useApolloQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const [data, setData] = useState<TData>();
  const all = useQuery<TData, TVariables>(query, options);
  useEffect(() => {
    if (!all.loading) {
      setData(old => (old !== all.data ? all.data : old));
    }
  }, [all.data, all.loading]);
  return useMemo(() => {
    return { ...all, data };
  }, [all, data]);
}
