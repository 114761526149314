import * as React from "react";
import { Config } from "./getConfig";

export const ConfigContext = React.createContext<Config>({
  apiServerUri: "",
  authServerUri: "",
  authClientId: "",
  authScope: "",
  publicUrl: "",
  basePath: ""
});
