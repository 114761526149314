import { Typography } from "@material-ui/core";
import { Text } from "components/text";
import React, { ErrorInfo, PureComponent } from "react";
import { Box } from "rebass";
import styled from "styled-components";

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

const Outer = styled(Box)`
  background-color: #ffcccc;
  border: solid 1px #cc0000;
`;

const Inner = styled(Box)`
  overflow-y: auto;
`;

class ErrorBoundary extends PureComponent<{}, State> {
  public state: State = {};

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
  }

  public render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error != null) {
      return (
        <Outer p={3} flex={"0 1 auto"}>
          <Typography variant="subtitle1">Fehler beim Laden eines Moduls</Typography>
          <Typography>{error.message}</Typography>
          <Inner>{errorInfo != null && <Text textStyle="stacktrace">{errorInfo.componentStack}</Text>}</Inner>
        </Outer>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
