import { useEffect, useState } from "react";

export function useDelayed(delay: number) {
  const [active, setActive] = useState(false);
  useEffect(() => {
    const timer = window.setTimeout(() => {
      setActive(true);
    }, delay);
    return () => window.clearTimeout(timer);
  }, [delay]);

  return active;
}
