import axios from "axios";
import URL from "url-parse";
import { Config } from "../../config";
import { ApiTokens } from "../ApiTokens";
import { isAxiosError } from "../isAxiosError";
import { TokenResponse } from "../TokenResponse";
import { saveTokens } from "../tokens";
import { NotActivatedError } from "./NotActivatedError";
import { NotVerifiedError } from "./NotVerifiedError";

interface NotActivatedResponse {
  error_description: "not_activated";
}

interface NotVerifiedResponse {
  error_description: "not_verified";
}

type BadRequest = NotActivatedResponse | NotVerifiedResponse;

let callLogin = async function(config: Config, userName: string, password: string) {
  const form = new FormData();
  form.append("grant_type", "password");
  form.append("scope", config.authScope);
  form.append("client_id", config.authClientId);
  form.append("username", userName);
  form.append("password", password);

  const url = new URL("connect/token", config.apiServerUri);
  try {
    const response = await axios.post<TokenResponse>(url.href, form);

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token
    };
  } catch (e) {
    if (isAxiosError(e) && e.response != null && e.response.status === 400) {
      const body = e.response.data as BadRequest;
      switch (body.error_description) {
        case "not_activated":
          throw new NotActivatedError("Nicht aktiviert");
        case "not_verified":
          throw new NotVerifiedError("Nicht verifiziert");
        default:
          // empty
          break;
      }
    }
    throw e;
  }
};

export const signInAsync = async (userName: string, password: string, config: Config): Promise<ApiTokens> => {
  let tokens = await callLogin(config, userName, password);
  await saveTokens(tokens);
  return tokens;
};
