import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { AppAction, appActions } from "./actions";
import { anonymous, AppState, AppStatus, initialAppState } from "./state";

let nextMessageId = 0;

const reducer: Reducer<AppState, AppAction> = (state = initialAppState, action) => {
  switch (action.type) {
    case getType(appActions.setLoadSuccess): {
      return {
        ...state,
        status: AppStatus.Loaded,
        loadError: undefined
      };
    }

    case getType(appActions.setLoadFailed): {
      return {
        ...state,
        loadError: action.payload.error,
        status: AppStatus.Failed
      };
    }

    case getType(appActions.signIn): {
      const user = action.payload.user;
      return {
        ...state,
        user: {
          ...user,
          isAnonymous: false
        },
        activeCustomer: user.customerAssignments.length === 0 ? undefined : user.customerAssignments[0]
      };
    }

    case getType(appActions.updateUser): {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user
        }
      };
    }

    case getType(appActions.setAnonymous): {
      return {
        ...state,
        user: anonymous
      };
    }

    case getType(appActions.enqueueSnackbar): {
      const { message, options } = action.payload;

      return {
        ...state,
        pendingSnackbarItems: [...state.pendingSnackbarItems, { id: (++nextMessageId).toString(), message, options }]
      };
    }

    case getType(appActions.removeSnackbar): {
      return {
        ...state,
        pendingSnackbarItems: state.pendingSnackbarItems.filter(c => c.id !== action.payload.id)
      };
    }

    case getType(appActions.clearPendingSnackbarItems): {
      return {
        ...state,
        pendingSnackbarItems: []
      };
    }

    case getType(appActions.changeCustomer): {
      return { ...state, activeCustomer: action.payload.customer };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
