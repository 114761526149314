import { appActions, selectPendingSnackbarItems } from "modules/app";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useSnackbarLogic = function() {
  const messages = useSelector(selectPendingSnackbarItems);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(messages.length > 0);

  const onClose = useCallback((event: SyntheticEvent, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }, []);

  const onExited = useCallback(() => {
    if (messages.length === 0) {
      return;
    }
    dispatch(appActions.removeSnackbar({ id: messages[0].id }));
  }, [dispatch, messages]);

  useEffect(() => {
    setOpen(messages.length > 0);
  }, [messages]);
  return { open, onClose, onExited, messages };
};
