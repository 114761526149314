import styled, { css } from "styled-components";

export default styled.p<{ noWrap?: boolean }>`
  ${({
    theme: {
      typography: { body1 }
    }
  }) => css`
    font-family: ${body1.fontFamily};
    font-size: ${body1.fontSize};
    color: ${body1.color};
    line-height: ${body1.lineHeight};
  `};
  ${({ noWrap }) =>
    Boolean(noWrap) &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;
