import { getConfig } from "./getConfig";

export function removeTrailingSlash(input: string) {
  const lastIndex = input.lastIndexOf("/");
  if (lastIndex === input.length - 1) {
    // remove trailing slash
    return input.substring(0, input.length - 1);
  }
  return input;
}
export function getRouterBasePath() {
  const config = getConfig();
  return removeTrailingSlash(config.basePath);
}
