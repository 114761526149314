import { getApolloClient } from "shared/api/Apollo";
import { SelectionOnMe as Me } from "./queries/me";
import query from "./queries/me.graphql";

export async function authenticate(): Promise<Me> {
  const client = getApolloClient();
  const result = await client.query<{ me: Me }>({
    query,
    fetchPolicy: "network-only"
  });
  return result.data.me;
}
