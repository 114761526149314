import { WithChildren } from "components/utils";
import React, { FC, memo } from "react";
import styled from "styled-components";
import Typography from "../Typography";

const StyledTypo = styled(Typography)`
  color: #008800;
`;

const SuccessText: FC<WithChildren> = ({ children }) => <StyledTypo color="inherit">{children}</StyledTypo>;

export default memo(SuccessText);
