import axios from "axios";

import { Store } from "redux";
import URL from "url-parse";
import { Config } from "../config";
import { ApiTokens } from "./ApiTokens";
import { TokenResponse } from "./TokenResponse";
import { clearTokens, loadTokens, saveTokens } from "./tokens";

const callRefresh = async (token: string, config: Config): Promise<ApiTokens> => {
  const form = new FormData();
  form.append("client_id", config.authClientId);
  form.append("grant_type", "refresh_token");
  form.append("refresh_token", token);

  const url = new URL("connect/token", config.authServerUri);
  const response = await axios.post<TokenResponse>(url.href, form);

  return {
    accessToken: response.data.access_token,
    refreshToken: response.data.refresh_token
  };
};

let currentRefreshRequest: Promise<ApiTokens> | null = null;

export function refresh(store: Store, config: Config): Promise<ApiTokens> {
  if (currentRefreshRequest != null) {
    return currentRefreshRequest;
  }

  currentRefreshRequest = new Promise<ApiTokens>((resolve, reject) => {
    loadTokens()
      .then(({ refreshToken }) => {
        if (refreshToken == null) {
          throw new Error("No refresh token available");
        }
        return callRefresh(refreshToken, config);
      })
      .then(resolve, reject);
  })
    .then(async tokens => {
      await saveTokens(tokens);
      return tokens;
    })
    .catch(e => {
      clearTokens();
      throw e;
    })
    .finally(() => {
      currentRefreshRequest = null;
    });

  return currentRefreshRequest;
}
