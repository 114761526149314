import { SagaIterator } from "redux-saga";
import { put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { appActions } from "../actions";

function* loadSaga() {
  yield put(appActions.setLoadSuccess());
}

function* loadWatcher(): SagaIterator {
  yield takeEvery(getType(appActions.load), loadSaga);
}

export default [loadWatcher];
