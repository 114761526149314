import * as React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppLoadingScreen from "./components/layout/AppLoadingScreen";
import { RouteComponentLoader } from "./components/loading";
import SnackbarQueue from "./components/page/SnackbarQueue";
import { appActions, AppStatus, selectAppStatus, selectLoadError } from "./modules/app";
import { selectCurrentTitle } from "./modules/title";
import Login from "./routes/login";
import Register from "./routes/register";
import Reset from "./routes/reset";
import ResetPassword from "./routes/reset-password";
import Root from "./routes/root";
import VerifyAccount from "./routes/verify-account";
import { getRouterBasePath } from "./shared/config/getRouterBasePath";

const Application: React.FC = () => {
  const dispatch = useDispatch();
  const status = useSelector(selectAppStatus);
  const error = useSelector(selectLoadError);
  const title = useSelector(selectCurrentTitle);

  useEffect(() => {
    dispatch(appActions.load());
  }, [dispatch]);

  switch (status) {
    case AppStatus.Loading:
      return <AppLoadingScreen />;
    case AppStatus.Loaded:
      const prefix = title === "" ? "" : `${title} - `;
      return (
        <>
          <Helmet title={`${prefix}LIFT IT`} />
          <Router basename={getRouterBasePath()}>
            <Switch>
              <Route exact render={p => <RouteComponentLoader component={Login} {...p} />} path="/login" />
              <Route exact render={p => <RouteComponentLoader component={Register} {...p} />} path="/register" />
              <Route exact render={p => <RouteComponentLoader component={Reset} {...p} />} path="/reset" />
              <Route
                render={p => <RouteComponentLoader component={ResetPassword} {...p} />}
                path="/reset-password/:token"
              />
              <Route
                render={p => <RouteComponentLoader component={VerifyAccount} {...p} />}
                path="/verify-account/:token"
              />
              <Route render={p => <RouteComponentLoader component={Root} {...p} />} />
            </Switch>
          </Router>
          <SnackbarQueue />
        </>
      );

    case AppStatus.Failed:
      return (
        <div>
          <p>Load error</p>
          {error && <pre>{error.message}</pre>}
        </div>
      );
    default:
      return null;
  }
};

export default Application;
