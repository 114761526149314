import { CardMedia, Divider } from "@material-ui/core";
import logo from "assets/logo.svg";
import React, { FC, memo } from "react";
import styled from "styled-components";

const StyledCardMedia = styled(CardMedia)`
  height: 100px;
  background-size: contain;
  margin: 0 16px;
`;

const CompanyCardHeader: FC = () => (
  <>
    <StyledCardMedia image={logo} title="LIFT IT" />
    <Divider />
  </>
);

export default memo(CompanyCardHeader);
