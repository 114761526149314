import { Typography } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";
import React, { FC } from "react";
import styled from "styled-components";
import {
  alignSelf,
  AlignSelfProps,
  flex,
  flexBasis,
  FlexBasisProps,
  FlexProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  textStyle,
  TextStyleProps
} from "styled-system";
import { WithClassName } from "../../utils";

export interface TextProps
  extends SpaceProps,
    FlexBasisProps,
    AlignSelfProps,
    FlexProps,
    PositionProps,
    TextStyleProps {}

const TextBase: FC<TypographyProps & WithClassName & TextProps> = ({ children, className, ...rest }) => {
  const { noWrap } = rest;
  return (
    <Typography className={className} noWrap={noWrap}>
      {children}
    </Typography>
  );
};

const Text = styled(TextBase)<TextProps>`
  ${textStyle};
  ${space};
  ${position};
  ${flex};
  ${flexBasis};
  ${alignSelf};
`;

export default Text;
