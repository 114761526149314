import { Typography as MuiTypography } from "@material-ui/core";
import { TypographyProps as MuiTypographyProps } from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export interface TypographyProps extends MuiTypographyProps, SpaceProps {}

const Typography = styled((props: TypographyProps) => <MuiTypography {...props} />)`
  ${space};
`;

Typography.defaultProps = {
  component: "p"
};

export default Typography;
