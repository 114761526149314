import { MuiThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import React, { Children, ReactChild } from "react";
import { ThemeProvider } from "styled-components";
import { muiTheme, theme } from "./theme";

const StyleInjector: React.FC = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>{Children.only(children) as ReactChild}</ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export default StyleInjector;
