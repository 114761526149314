import axios from "axios";
import URL from "url-parse";
import { Config } from "../../config";

interface ResendVerificationEmailRequest {
  email: string;
}

export const resendVerificationEmailAsync = async (request: ResendVerificationEmailRequest, config: Config) => {
  const url = new URL("api/v1/resend-verification-mail-requests", config.apiServerUri);
  await axios.post(url.href, request);
};
