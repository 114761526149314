import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, Observable } from "apollo-link";
import { setContext } from "apollo-link-context";
import { ErrorResponse, onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { ServerError } from "apollo-link-http-common";
import { Store } from "redux";
import URL from "url-parse";
import { Config, getConfig } from "../config";
import { refresh } from "./refresh";
import { loadTokens } from "./tokens";

function isServerError(error: ErrorResponse["networkError"]): error is ServerError {
  const anyError = error as any;
  return anyError.statusCode != null;
}

export function initializeApollo(store: Store, apiConfig: Config) {
  if (apolloClient != null) {
    throw new Error("Apollo already i");
  }
  const config = getConfig();
  const graphQLUri = new URL("graphql", config.apiServerUri);
  const httpLink = createHttpLink({
    uri: graphQLUri.href
  });

  const errorLink = onError(args => {
    const { networkError, operation, forward } = args;
    if (networkError && isServerError(networkError) && networkError.statusCode === 401) {
      return new Observable(observer => {
        refresh(store, apiConfig)
          .then(newTokens => {
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                // Re-add old headers
                ...oldHeaders,
                // Switch out old access token for new one
                authorization: `Bearer ${newTokens.accessToken}`
              }
            });
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            };

            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch(error => {
            observer.error(error);
          });
      });
    }
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await loadTokens();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token.accessToken}` : ""
      }
    };
  });

  apolloClient = new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });

  return apolloClient;
}

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export function getApolloClient() {
  if (apolloClient == null) {
    throw new Error("Apollo not initialized. Call initializeApollo first");
  }
  return apolloClient;
}
