import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocale from "date-fns/locale/de";
import React, { FC } from "react";

function createMuiPickersUtilsProvider() {
  const ConfiguredPickers: FC = ({ children }) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} children={children} locale={deLocale} />
  );
  return ConfiguredPickers;
}

export default createMuiPickersUtilsProvider;
