import { defaultMemoize } from "reselect";
import URL from "url-parse";

export interface Config {
  apiServerUri: string;
  authServerUri: string;
  authClientId: string;
  authScope: string;
  publicUrl: string;
  basePath: string;
}

const parseConfig = (): Config => {
  const baseConfig = (window as any).env as Omit<Config, "basePath">;
  if (/^https?:\/\/|^\/\//i.test(baseConfig.publicUrl)) {
    const url = URL(baseConfig.publicUrl);
    return { ...baseConfig, basePath: url.pathname };
  } else {
    return { ...baseConfig, basePath: "/" };
  }
};

export const getConfig = defaultMemoize(parseConfig);
