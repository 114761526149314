import { createMuiTheme, Theme as MuiTheme } from "@material-ui/core/styles";
import { Theme as SSTheme } from "styled-system";

export type Theme = Omit<MuiTheme, "breakpoints"> &
  Omit<SSTheme, "shadows"> & { muiBreakpoints: MuiTheme["breakpoints"] };

export const muiTheme: MuiTheme = {
  ...createMuiTheme({
    palette: {
      primary: {
        main: "#861A22"
      },
      secondary: {
        main: "rgb(0,0,0)"
      }
    }
  })
};

const { breakpoints: muiBreakpoints, ...rest } = muiTheme;
export const theme: Theme = {
  ...rest,
  muiBreakpoints,
  textStyles: {
    userInfoName: {
      fontSize: "1rem"
    },
    userInfoEmail: {
      fontSize: "0.8rem",
      color: "rgba(0,0,0,0.5)"
    },
    stacktrace: {
      fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
      fontSize: "10px",
      whiteSpace: "pre-wrap"
    }
  }
};
