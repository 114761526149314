import { CssInitializer } from "components/utils";
import React, { ComponentType, FC, memo } from "react";
import createApolloHooksProvider from "./shared/boot/createApolloHooksProvider";
import createMuiPickersUtilsProvider from "./shared/boot/createMuiPickersUtilsProvider";
import { ConfigInjector, StoreInjector, StyleInjector } from "./shared/config";
import { nestComponents } from "./shared/nestComponents";

export interface AppStackProps {
  component: ComponentType;
}

const AppConfig: FC<AppStackProps> = ({ component: Component }) => {
  return (
    <>
      {nestComponents(
        createApolloHooksProvider(),
        StoreInjector,
        StyleInjector,
        createMuiPickersUtilsProvider(),
        ConfigInjector,
        CssInitializer,
        Component
      )}
    </>
  );
};

export default memo(AppConfig);
