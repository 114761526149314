import { createSelector } from "reselect";
import { RootState } from "../root";

export const selectTitleState = (state: RootState) => state.title;

export const selectCurrentTitle = createSelector(
  selectTitleState,
  state => {
    if (state.items.length === 0) {
      return "";
    }
    return state.items[state.items.length - 1].title;
  }
);
