import { ReactNode } from "react";
import { ActionType, createAction } from "typesafe-actions";
import { Me, PartialCustomerAssignment, SnackbarOptions } from "./state";

export const appActions = {
  setLoadSuccess: createAction("APP/SET_LOAD_SUCCESS")<undefined>(),
  setLoadFailed: createAction("APP/SET_LOAD_FAILED")<{ error: Error }>(),
  signOut: createAction("APP/SIGN_OUT")<undefined>(),
  signIn: createAction("APP/SIGN_IN")<{ user: Me }>(),
  updateUser: createAction("APP/UPDATE_USER")<{ user: Partial<Me> }>(),
  setAnonymous: createAction("APP/SET_ANONYMOUS")<undefined>(),
  load: createAction("APP/LOAD")<undefined>(),
  enqueueSnackbar: createAction("APP/ENQUEUE_SNACKBAR")<{
    message: string | ReactNode;
    options?: SnackbarOptions;
  }>(),
  removeSnackbar: createAction("APP/REMOVE_SNACKBAR")<{ id: string }>(),
  clearPendingSnackbarItems: createAction("APP/CLEAR_PENDING_SNACKBAR_ITEMS")<undefined>(),
  changeCustomer: createAction("APP/CHANGE_CUSTOMER")<{ customer: PartialCustomerAssignment }>()
};

export type AppAction = ActionType<typeof appActions>;
