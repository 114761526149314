import { GridConfig } from "modules/grids";
import { useMemo } from "react";
import { Page } from "../model";

export function usePrepareGridData<T>(data: { readonly data: Page<T> } | undefined, config: GridConfig): Page<T> {
  return useMemo(() => {
    const actualData = data != null && data.data != null ? data.data : undefined;
    const rows = actualData != null ? actualData.items : [];
    const res: Page<T> =
      actualData == null
        ? {
            page: config.page,
            pageSize: config.pageSize,
            items: rows,
            totalCount: 0,
            totalPages: 0
          }
        : {
            page: actualData.page,
            pageSize: actualData.pageSize,
            items: rows,
            totalCount: actualData.totalCount,
            totalPages: actualData.totalPages
          };

    return res;
  }, [config, data]);
}
