import { Grow as Transition } from "@material-ui/core";
import logo from "assets/logo.svg";
import { Fullscreen } from "components/layout";
import { Spinner } from "components/loading";
import { ResponsiveImage } from "components/ui";
import React, { FC, memo } from "react";
import { Box, Flex } from "rebass";
import { useDelayed } from "shared/hooks";
import styled from "styled-components";

const ImageContainer = styled(Box)`
  max-width: 300px;
`;

const AppLoadingScreen: FC = () => {
  const show = useDelayed(500);
  return (
    <Fullscreen>
      <Transition in={show} timeout={250}>
        <Flex flexDirection="row" alignItems="center" m={2}>
          <Spinner />
          <ImageContainer ml={2}>
            <ResponsiveImage src={logo} alt="Logo LIFT IT GmbH" />
          </ImageContainer>
        </Flex>
      </Transition>
    </Fullscreen>
  );
};

export default memo(AppLoadingScreen);
