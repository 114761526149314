import { Card } from "components/cards";
import { WithChildren } from "components/utils";
import React, { FC, memo } from "react";
import { Box } from "rebass";
import styled from "styled-components";

export interface FullScreenDialogProps extends WithChildren {
  maxWidth?: number;
}

const Outer = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow-y: auto;
  align-items: center;
`;

const StyledCard = styled(Card)<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => (maxWidth == null ? 400 : maxWidth)}px;
  width: 100vw;
  margin: auto;
`;
const FullScreenDialog: FC<FullScreenDialogProps> = ({ children }) => {
  return (
    <Outer>
      <StyledCard>{children}</StyledCard>
    </Outer>
  );
};

export default memo(FullScreenDialog);
