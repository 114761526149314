import { SnackbarProps } from "@material-ui/core/Snackbar";
import { ReactNode } from "react";
import { SelectionOnCustomerAssignments, SelectionOnMe } from "../../routes/root/queries/me";

export enum AppStatus {
  Loading,
  Loaded,
  Failed
}

export type PartialCustomerAssignment = SelectionOnCustomerAssignments;
export type Me = SelectionOnMe;

export type UserInfo = Me & {
  readonly isAnonymous: boolean;
};

export interface SnackbarOptions
  extends Pick<
    SnackbarProps,
    | "action"
    | "anchorOrigin"
    | "autoHideDuration"
    | "ClickAwayListenerProps"
    // | "ContentProps"
    | "disableWindowBlurListener"
    | "resumeHideDuration"
    // | "TransitionComponent"
    | "transitionDuration"
    | "TransitionProps"
  > {
  variant?: "success" | "error" | "warning" | "info";
}

export interface PendingSnackbarItem {
  readonly id: string;
  readonly message: string | ReactNode;
  readonly options?: SnackbarOptions;
}

export interface AppState {
  readonly status: AppStatus;
  readonly loadError?: Error;
  readonly user: UserInfo;
  readonly activeCustomer?: PartialCustomerAssignment;
  readonly pendingSnackbarItems: PendingSnackbarItem[];
}

export const anonymous: UserInfo = {
  id: "",
  displayName: "Arno Nymous",
  email: "info@liftit24.de",
  version: "0",
  grants: [],
  isAnonymous: true,
  customerAssignments: []
};

export const initialAppState: AppState = {
  status: AppStatus.Loading,
  user: anonymous,
  pendingSnackbarItems: []
};
