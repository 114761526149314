import React, { ComponentType, FC, Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ErrorBoundary } from "../../utils";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

export interface OwnProps {
  component: ComponentType<RouteComponentProps<any>>;
  fullscreen?: boolean;
}

export type RouteComponentLoaderProps = RouteComponentProps<any> & OwnProps;

const RouteComponentLoader: FC<RouteComponentLoaderProps> = ({
  children,
  fullscreen,
  component: Component,
  ...rest
}) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingIndicator fullscreen={fullscreen} />}>
        <Component {...rest} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default RouteComponentLoader;
