import { appActions, SnackbarOptions } from "modules/app";
import { ReactNode, useCallback } from "react";
import { useDispatch } from "react-redux";

export function useSnackbar() {
  const dispatch = useDispatch();

  const enqueueSnackbar = useCallback(
    (message: string | ReactNode, options?: SnackbarOptions) => {
      dispatch(appActions.enqueueSnackbar({ message, options }));
    },
    [dispatch]
  );

  return { enqueueSnackbar };
}
