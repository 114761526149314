import { AppState, initialAppState } from "../app";
import { GridsState, initialGridsState } from "../grids";
import { initialTitleState, TitleState } from "../title";

export interface RootState {
  readonly app: AppState;
  readonly title: TitleState;
  readonly grids: GridsState;
}

export const initialState: RootState = {
  app: initialAppState,
  title: initialTitleState,
  grids: initialGridsState
};
