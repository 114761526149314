import AppLoadingScreen from "components/layout/AppLoadingScreen";
import { RouteComponentLoader } from "components/loading";
import { appActions } from "modules/app";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteComponentProps } from "react-router-dom";
import { useCurrentUser, useSnackbar } from "shared/hooks";
import Planner from "../planner";
import { authenticate } from "./authenticate";

const Root: React.FC<RouteComponentProps> = ({ history }) => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const callAuth = useCallback(
    async function() {
      try {
        const response = await authenticate();
        dispatch(appActions.signIn({ user: response }));
        enqueueSnackbar(
          <span>
            Willkommen, <strong>{response.displayName}</strong>.
          </span>,
          { variant: "success" }
        );
      } catch (e) {
        // ignore
      } finally {
        setReady(true);
      }
    },
    [dispatch, enqueueSnackbar]
  );

  useEffect(() => {
    callAuth().then(() => {
      /* ignore*/
    });
  }, [callAuth]);

  useEffect(() => {
    if (ready && user.isAnonymous) {
      history.push("/login");
    }
  }, [ready, history, user.isAnonymous]);

  if (!ready) {
    return <AppLoadingScreen />;
  }

  if (user.isAnonymous) {
    return null;
  }
  return <Route render={p => <RouteComponentLoader fullscreen component={Planner} {...p} />} />;
};

export default memo(Root);
