export enum SortDirection {
  Ascending,
  Descending
}

export interface Order {
  readonly field: string;
  readonly direction: SortDirection;
}

export interface GridConfig {
  readonly page: number;
  readonly pageSize: number;
  readonly order: Order[];
  readonly search: string | null;
}

export interface GridsState {
  readonly configs: {
    [key: string]: GridConfig;
  };
}

export const initialGridsState = {
  configs: {}
};
