import axios from "axios";
import URL from "url-parse";
import { Config } from "../../config";

export interface VerifyAccountRequest {
  token: string;
}

export const verifyAccountAsync = async (request: VerifyAccountRequest, config: Config): Promise<void> => {
  const url = new URL("api/v1/account-verifications", config.apiServerUri);
  await axios.post(url.href, request);
};
