import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { TitleAction, titleActions } from "./actions";
import { initialTitleState, TitleState } from "./state";

const reducer: Reducer<TitleState, TitleAction> = (state = initialTitleState, action) => {
  switch (action.type) {
    case getType(titleActions.pushTitle): {
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    }
    case getType(titleActions.replaceTitle): {
      const { id, title } = action.payload;
      return {
        ...state,
        items: state.items.map(c => (c.id === id ? { ...c, title } : c))
      };
    }
    case getType(titleActions.removeTitle): {
      const { id } = action.payload;
      return { ...state, items: state.items.filter(c => c.id !== id) };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
