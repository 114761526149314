import React, { FC } from "react";
import { Flex } from "rebass";
import styled from "styled-components";
import { WithChildren } from "../../utils";

const Container = styled(Flex)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
//
// Fullscreen.defaultProps = {
//   flexDirection: "row",
//   justifyContent: "center",
//   alignItems: "center"
// };

const Fullscreen: FC<WithChildren> = ({ children }) => (
  <Container flexDirection="row" justifyContent="center" alignItems="center">
    {children}
  </Container>
);

export default Fullscreen;
