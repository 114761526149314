import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { clearTokens } from "shared/api";
import { getApolloClient } from "shared/api/Apollo";
import { getType } from "typesafe-actions";
import { appActions } from "../actions";

function* signOutSaga() {
  const client = getApolloClient();
  try {
    yield call(clearTokens);
    yield call([client, client.clearStore]);
    yield put(appActions.setAnonymous());
    yield put(appActions.enqueueSnackbar({ message: "Sie wurden abgemeldet", options: { variant: "success" } }));
  } catch (e) {
    yield put(
      appActions.enqueueSnackbar({ message: `Fehler beim Abmelden: ${e.message}`, options: { variant: "error" } })
    );
  }
}

function* signOutWatcher(): SagaIterator {
  yield takeEvery(getType(appActions.signOut), signOutSaga);
}

export default [signOutWatcher];
