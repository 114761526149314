import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import React, { FC } from "react";
import { initializeApollo } from "../api/Apollo";
import { getConfig, getStore } from "../config";

function createApolloHooksProvider<T>() {
  const apolloClient = initializeApollo(getStore(), getConfig());
  const ConfiguredApolloHooksProvider: FC = ({ children }) => (
    <ApolloHooksProvider client={apolloClient} children={children} />
  );
  return ConfiguredApolloHooksProvider;
}

export default createApolloHooksProvider;
