import { initialState } from "modules/root";
import { rootReducer } from "modules/root/reducer";
import { rootSaga } from "modules/root/sagas";
import { applyMiddleware, compose, createStore, Store } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

const windowIfDefined = typeof window === "undefined" ? null : (window as any);
const composeEnhancers = (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function configureStore(): Store {
  // configure middlewares
  const sagaMiddleware = createSagaMiddleware();
  const middlewares: any[] = [sagaMiddleware];

  if (process.env.NODE_ENV === "development") {
    middlewares.push(
      createLogger({
        collapsed: true,
        duration: true
      })
    );
  }

  // compose enhancers
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  // create store
  const store = createStore(rootReducer, initialState, enhancer);
  sagaMiddleware.run(rootSaga);
  return store;
}
