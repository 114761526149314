import { createSelector } from "reselect";
import { RootState } from "../root";

export const selectDataGridsState = (state: RootState) => state.grids;

export const createConfigSelector = (name: string) =>
  createSelector(
    selectDataGridsState,
    state => {
      return state.configs[name];
    }
  );
