import { ApiTokens } from "./ApiTokens";

const TokensKey = "AUTH_TOKENS";

export function loadTokens(): Promise<ApiTokens> {
  const value = window.localStorage.getItem(TokensKey);
  if (value == null || value === "") {
    return Promise.resolve({});
  }

  const parsed = JSON.parse(value);
  const result: ApiTokens = {
    accessToken: parsed.accessToken,
    refreshToken: parsed.refreshToken
  };

  return Promise.resolve(result);
}

export async function saveTokens(tokens: ApiTokens): Promise<any> {
  window.localStorage.setItem(TokensKey, JSON.stringify(tokens));
  return Promise.resolve(undefined);
}

export function clearTokens(): Promise<any> {
  window.localStorage.removeItem(TokensKey);
  return Promise.resolve(undefined);
}
