import { createSelector } from "reselect";
import { RootState } from "../root";
import { PartialCustomerAssignment, UserInfo } from "./state";

export const selectAppState = (state: RootState) => state.app;

export const selectAppStatus = createSelector(
  selectAppState,
  app => app.status
);

export const selectLoadError = createSelector(
  selectAppState,
  app => app.loadError
);

export const selectIsSignedIn = createSelector(
  selectAppState,
  app => !app.user.isAnonymous
);

export const selectActiveCustomerAssignment = createSelector(
  selectAppState,
  app => app.activeCustomer
);
export const selectUser = createSelector(
  selectAppState,
  app => app.user
);

export const selectPendingSnackbarItems = createSelector(
  selectAppState,
  app => app.pendingSnackbarItems
);

export function isAdmin(user: UserInfo) {
  return user.grants != null && user.grants.some(c => c === "Administrator");
}

export function hasCustomers(user: UserInfo) {
  return user.customerAssignments != null && user.customerAssignments.length > 0;
}

export function isTenant(user: UserInfo, activeCustomer: PartialCustomerAssignment | undefined) {
  return activeCustomer != null && activeCustomer.isTenant;
}
