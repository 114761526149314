import { SnackbarContent } from "@material-ui/core";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import { SnackbarOptions } from "modules/app";
import React from "react";
import styled from "styled-components";

type StyledSnackbarContentProps = {
  variant: SnackbarOptions["variant"];
};
type P = StyledSnackbarContentProps;
const StyledSnackbarContent = styled(({ variant, ...rest }) => <SnackbarContent {...rest} />)<P>`
  background-color: ${({ theme, variant }) => {
    switch (variant) {
      case "error":
        return theme.palette.error.dark;
      case "warning":
        return amber[700];
      case "info":
        return theme.palette.primary.dark;
      case "success":
        return green[600];
      default:
        return "inherit";
    }
  }};
`;
export default StyledSnackbarContent;
