import axios from "axios";
import URL from "url-parse";
import { Config } from "../../config";

export interface CreateUserRequest {
  email: string;
  displayName: string;
  password: string;
  passwordConfirmation: string;
  suggestedCustomerNumber: string;
  suggestedCompany: string;
}

export const createAccountAsync = async (request: CreateUserRequest, config: Config): Promise<void> => {
  const url = new URL("api/v1/accounts", config.apiServerUri);
  await axios.post(url.href, request);
};
