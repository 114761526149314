import { Card as Mui } from "@material-ui/core";
import { CardProps as MuiProps } from "@material-ui/core/Card";
import * as React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

const Card = styled((props: MuiProps & SpaceProps) => <Mui {...props} />)`
  ${space};
`;

export default Card;
