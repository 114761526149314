export interface TitleItem {
  readonly id: string;
  readonly title: string;
}

export interface TitleState {
  readonly items: TitleItem[];
}

export const initialTitleState: TitleState = {
  items: []
};
