import { Snackbar } from "@material-ui/core";
import React, { FC, memo, useMemo } from "react";
import StyledSnackbarContent from "./StyledSnackbarContent";
import { useSnackbarLogic } from "./useSnackbarLogic";

const SnackbarQueue: FC = () => {
  const { open, onClose, onExited, messages } = useSnackbarLogic();

  const derivedProps = useMemo(() => {
    if (messages == null || messages.length === 0) {
      return {};
    }
    const top = messages[0];
    const defaultProps = {
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      }
    };
    if (top.options == null) {
      return defaultProps;
    }
    const { variant, ...rest } = top.options;
    return { ...defaultProps, ...rest };
  }, [messages]);

  if (messages.length === 0) {
    return null;
  }
  const top = messages[0];

  return (
    <Snackbar key={top.id} open={open} onClose={onClose} onExited={onExited} {...derivedProps}>
      <StyledSnackbarContent message={top.message} variant={top.options == null ? undefined : top.options.variant} />
    </Snackbar>
  );
};

export default memo(SnackbarQueue);
