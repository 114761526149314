import * as React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import configureStore from "./store";

const store: Store = configureStore();

export function getStore() {
  return store;
}

const StoreInjector: React.FC = ({ children }) => <Provider store={store}>{children}</Provider>;

export default StoreInjector;
