import { Grow } from "@material-ui/core";
import spinner from "assets/spinner.svg";
import React, { FC, memo, useEffect, useState } from "react";
import { Box, Flex } from "rebass";
import styled, { css } from "styled-components";

export interface LoadingIndicatorProps {
  delay?: number;
  fullscreen?: boolean;
}

const Content = styled(Flex)<{ fullscreen: boolean }>`
  ${({ fullscreen }) =>
    fullscreen
      ? css`
          width: 100vw;
          height: 100vh;
        `
      : css`
          width: 100%;
          height: 100%;
        `};
`;

const LoadingIndicator: FC<LoadingIndicatorProps> = ({ delay, fullscreen }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setShow(true);
    }, delay);
    return () => window.clearTimeout(timeout);
  }, [setShow, delay]);

  return (
    <Grow in={show} timeout={100}>
      <Content alignItems="center" justifyContent="center" fullscreen={Boolean(fullscreen)}>
        <Box>
          <img src={spinner} alt="Lade..." />
        </Box>
      </Content>
    </Grow>
  );
};

LoadingIndicator.defaultProps = {
  delay: 0
};
export default memo(LoadingIndicator);
